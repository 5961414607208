import snakecaseKeys from 'snakecase-keys';
import camelcaseKeys from 'camelcase-keys';
import {
  generatePath,
} from 'react-router-dom';
import { notification } from 'antd';

import http from '@/http';
import { usersAPI } from '@/constants/api';
import { getAuthUser, setAuthUser } from '@/utils/localStorageUtil';

export async function getUsers({ email, current, pageSize = 10 }) {
  try {
    const params = snakecaseKeys({
      email,
      limit: pageSize,
      page: current,
    });
    const {
      status,
      data: { data, pagination }
    } = await http.get(usersAPI.index, { params });
    if (status === 200) {
      return {
        success: true,
        data: camelcaseKeys(data, { deep: true }),
        pagination: camelcaseKeys(pagination, { deep: true })
      };
    }
    return {
      success: false,
      data: [],
      pagination: {}
    };
  } catch (error) {
    notification.error({
      duration: 5,
      placement: 'bottom',
      description: error.response.data?.message,
    });

    return {
      success: false,
      data: [],
      pagination: {}
    };
  }
}


export async function getUser({ id }) {
  try {
    const url = generatePath(usersAPI.detail, { id });
    const { status: resStatus, data } = await http.get(url);
    if (resStatus === 200) {
      return {
        success: true,
        data
      };
    }
    return {
      success: false,
      data: null
    };
  } catch (error) {
    notification.error({
      duration: 5,
      placement: 'bottom',
      description: error.response.data?.message,
    });

    return {
      success: false,
      data: null
    };
  }
}

export async function updateGroups({ id, groupIds }) {
  try {
    const params = snakecaseKeys({
      id,
      groupIds
    });
    const url = generatePath(usersAPI.addGroup, { id });
    const {
      status: resStatus,
      data,
    } = await http.post(url, params);
    if (resStatus === 200) {
      notification.success({
        duration: 5,
        placement: 'bottom',
        description: 'Update Permission Successfully!',
      });

      return {
        success: true,
        data: camelcaseKeys(data || [], { deep: true }),
      };
    }
    return {
      success: false,
      data: [],
    };
  } catch (error) {
    notification.error({
      duration: 5,
      placement: 'bottom',
      description: error.response.data?.message,
    });

    return {
      success: false,
      data: [],
    };
  }
}

export async function getProfile() {
  try {
    const res = await http.get(usersAPI.profile);
    const data = camelcaseKeys(res.data, { deep: true });
    if (res.status === 200) {
      const user = getAuthUser();
      setAuthUser({
        ...user,
        ...data,
        permissions: data.permissions || [],
      });
      return {
        success: true,
        data,
      };
    }
    return {
      success: false,
      data: null
    };
  } catch (error) {
    notification.error({
      duration: 5,
      placement: 'bottom',
      description: error.response.data?.message,
    });

    return {
      success: false,
      data: null
    };
  }
}
