export const PERMISSION = {
  KYC_READ: 'read:kyc',
  KYC_WRITE: 'write:kyc',
  TRANSACTION_READ: 'read:transaction',
  TRANSACTION_WRITE: 'write:transaction',
  GROUP_READ: 'read:group',
  GROUP_WRITE: 'write:group',
  USER_READ: 'read:user',
  USER_WRITE: 'write:user'
};

export const OPTS_PERMISSION = [
  { label: 'Read KYC', value: PERMISSION.KYC_READ },
  { label: 'Write KYC', value: PERMISSION.KYC_WRITE },
  { label: 'Read Transaction', value: PERMISSION.TRANSACTION_READ },
  { label: 'Write Transaction', value: PERMISSION.TRANSACTION_WRITE },
  { label: 'Read Group', value: PERMISSION.GROUP_READ },
  { label: 'Write Group', value: PERMISSION.GROUP_WRITE },
  { label: 'Read User', value: PERMISSION.USER_READ },
  { label: 'Write User', value: PERMISSION.USER_WRITE },
];

export const PERMISSION_NAME = {
  [PERMISSION.KYC_READ]: 'Read KYC',
  [PERMISSION.KYC_WRITE]: 'Write KYC',
  [PERMISSION.GROUP_READ]: 'Read Group',
  [PERMISSION.GROUP_WRITE]: 'Write Group',
  [PERMISSION.TRANSACTION_READ]: 'Read Transaction',
  [PERMISSION.TRANSACTION_WRITE]: 'Write Transaction',
  [PERMISSION.USER_READ]: 'Read User',
  [PERMISSION.USER_WRITE]: 'Write User'
};
