import React from 'react';
import KycContainer from '@/containers/KycContainer';

function KycPage() {
  return (
    <KycContainer />
  );
}

export default KycPage;
