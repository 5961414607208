import React from 'react';
import PropTypes from 'prop-types';
import {
  Card,
  Col,
  Row,
  Button,
  Space
} from 'antd';
import {
  uniqueId,
  startCase
} from 'lodash';
import moment from 'moment';

import HistoryLog from '@/components/Kyc/HistoryLog';
import { hasPermission } from '@/utils/permission';
import { PERMISSION } from '@/constants/permission';

function KycDetailComponent({ data, openFile, showConfirmAprove, handleOpenRejectForm, onBack }) {
  const renderRow = ({ label, value }) => {
    if (typeof value === 'object' && value?.type === 'application/pdf') {
      return (
        <React.Fragment key={uniqueId('col-kyc-')}>
          <Col xs={{ span: 12 }} xl={{ span: 6 }}>
            <p><b>{startCase(label)}: </b></p>
          </Col>
          <Col xs={{ span: 12 }} xl={{ span: 6 }}>
            {
              value.id ? (
                <a href='##' onClick={() => openFile(value.id)}>{value.name}</a>
              ) : (
                <p>-</p>
              )
            }
          </Col>
        </React.Fragment>
      );
    };
    return (
      <React.Fragment key={uniqueId('col-kyc-')}>
        <Col xs={{ span: 12 }} xl={{ span: 6 }}>
          <p><b>{startCase(label)}: </b></p>
        </Col>
        <Col xs={{ span: 12 }} xl={{ span: 6 }}>
          <p>{value || '-'}</p>
        </Col>
      </React.Fragment>
    );
  };
  const renderActionKYC = () => {
    if (!hasPermission(PERMISSION.KYC_WRITE) || data.status === 'Activated') {
      return null;
    }
    return (
      <Space size="middle">
        <Button
          type="primary"
          onClick={() => showConfirmAprove(data.id)}
        >
          Approve
        </Button>
        {
          data.status === 'Submitted' && (
            <Button
              type="danger"
              onClick={() => handleOpenRejectForm(data.id)}
            >
              Reject
            </Button>
          )
        }
      </Space>
    );
  };
  const generalInfo = {
    'ID': 'id',
    'Merchant ID': 'merchantId',
    'Status': 'status',
    'Reject Reason': 'rejectReason',
    'Reject Reason Code': 'rejectReasonCode'
  };
  const contactInfo = {
    'Contact Name': 'contactName',
    'Contact Email': 'contactEmail',
    'Contact Number': 'contactNumber',
  };
  const businessOverview = {
    'Business Type': 'businessType',
    'Business Category': 'businessCategory',
    'Business Description': 'businessDescription',
    'Accept Payment Website Url': 'acceptPaymentWebsiteUrl',
    'Accept Payment App Url': 'acceptPaymentAppUrl'
  };
  const panDetail = {
    'Business PAN': 'businessPan',
    'Business Name': 'businessName',
    'Authorised Signatory PAN': 'pan',
    'PAN Owner’s Name': 'panName',
    'CIN': 'cin'
  };
  const billingDetail = {
    'Billing Label': 'billingLabel',
    'Address': 'address',
    'PIN code': 'pinCode',
    'City': 'city',
    'State': 'stateCode',
    'Operation Address same as above': 'isOperationSameAbove',
  };

  const operationDetail = {
    'Address': 'operationAddress',
    'PIN code': 'operationPinCode',
    'City': 'operationCity',
    'State': 'operationStateCode',
    'Company Registration Certificate File': 'companyRegistrationCertificateFile',
    'Cancelled Cheque File': 'cancelledChequeFile'
  };

  const historyLogData = (data.reviewHistory || []).map(item => ({
    key: item.id,
    reason: item.reason,
    status: item.status,
    reviewAt: moment(item.reviewAt).format('YYYY-MM-DD HH:mm Z'),
    rejectedCode: item.rejectedCode,
    reviewBy: `${item.reviewBy?.name} (${item.reviewBy?.email})`,
  }));

  return (
    <div className="site-card-border-less-wrapper">
      <div className='kyc-detail__btn-group'>
        <Button shape="round" onClick={onBack}>
          Back
        </Button>
        {renderActionKYC()}
      </div>
      <Card title="General Info" className='kyc-detail__card'>
        <Row gutter={[16, 16]}>
          {
            Object.keys(generalInfo).map(key =>
              renderRow({ label: key, value: data[generalInfo[key]] })
            )
          }
        </Row>
      </Card>
      <Card title="Contact Info" className='kyc-detail__card'>
        <Row gutter={[16, 16]}>
          {
            Object.keys(contactInfo).map(key =>
              renderRow({ label: key, value: data[contactInfo[key]] })
            )
          }
        </Row>
      </Card>
      <Card title="Business Overview" className='kyc-detail__card'>
        <Row gutter={[16, 16]}>
          {
            Object.keys(businessOverview).map(key =>
              renderRow({ label: key, value: data[businessOverview[key]] })
            )
          }
        </Row>
      </Card>
      <Card title="Business Details" className='kyc-detail__card'>
        <Card title="PAN Details" className='kyc-detail__card' bordered={false}>
          <Row gutter={[16, 16]}>
            {
              Object.keys(panDetail).map(key =>
                renderRow({ label: key, value: data[panDetail[key]] })
              )
            }
          </Row>
        </Card>
        <Card title="Billing Details" className='kyc-detail__card' bordered={false}>
          <Row gutter={[16, 16]}>
            {
              Object.keys(billingDetail).map(key =>
                renderRow({ label: key, value: data[billingDetail[key]] })
              )
            }
          </Row>
        </Card>
        <Card title="Operation Details" className='kyc-detail__card' bordered={false}>
          <Row gutter={[16, 16]}>
            {
              Object.keys(operationDetail).map(key =>
                renderRow({ label: key, value: data[operationDetail[key]] })
              )
            }
          </Row>
        </Card>
      </Card>
      <Card title="History Log" className='kyc-detail__card'>
        <HistoryLog data={historyLogData} />
      </Card>
    </div>
  );
}

KycDetailComponent.propTypes = {
  data: PropTypes.object,
  openFile: PropTypes.func,
  showConfirmAprove: PropTypes.func,
  handleOpenRejectForm: PropTypes.func,
  onBack: PropTypes.func
};

export default KycDetailComponent;
