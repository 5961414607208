import React from 'react';
import PropTypes from 'prop-types';
import {
  Tag,
  Table,
} from 'antd';
import {
  uniqueId,
  capitalize,
} from 'lodash';

import { KycRejectCodes, KycReviewStatuses } from '@/constants/kyc';

HistoryLog.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.object,
  ).isRequired,
};

function HistoryLog({
  data
}) {
  const columns = [
    {
      title: 'Review By',
      dataIndex: 'reviewBy',
      key: 'reviewBy',
    },
    {
      title: 'KYC Review status',
      dataIndex: 'status',
      key: 'status',
      render: (status) => {
        const text = Object.keys(KycReviewStatuses).find(key => KycReviewStatuses[key] === status);

        return (
          <Tag
            key={uniqueId}
            className={`kyc-review-status-${text?.toLowerCase()}`}
          >
            {capitalize(text)}
          </Tag>
        );
      }
    },
    {
      title: 'Reason',
      dataIndex: 'reason',
      key: 'reason',
    },
    {
      title: 'Rejected Code',
      dataIndex: 'rejectedCode',
      key: 'rejectedCode',
      render: (rejectedCode) => {
        const rejectReasonCode = KycRejectCodes.find(item => item.value === rejectedCode);

        return rejectReasonCode?.text;
      }
    },
    {
      title: 'Review At',
      dataIndex: 'reviewAt',
      key: 'reviewAt',
    },
  ];

  return (
    <div className='history-log-container'>
      <Table
        dataSource={data}
        columns={columns}
        pagination={false}
        size="middle"
        scroll={{
          y: 310,
          x: 768,
        }}
      />
    </div>
  );
}

export default HistoryLog;
