import snakecaseKeys from 'snakecase-keys';
import camelcaseKeys from 'camelcase-keys';
import {
  generatePath,
} from 'react-router-dom';
import { notification } from 'antd';

import http from '@/http';
import { groupsApi } from '@/constants/api';

export async function getGroups({ name, active, current, pageSize = 10 }) {
  try {
    const params = snakecaseKeys({
      name,
      active,
      limit: pageSize,
      page: current,
    });
    const {
      status,
      data: { data, pagination }
    } = await http.get(groupsApi.index, { params });
    if (status === 200) {
      return {
        success: true,
        data: camelcaseKeys(data || [], { deep: true }),
        pagination: camelcaseKeys(pagination, { deep: true })
      };
    }
    return {
      success: false,
      data: [],
      pagination: {}
    };
  } catch (error) {
    notification.error({
      duration: 5,
      placement: 'bottom',
      description: error.response.data?.message,
    });

    return {
      success: false,
      data: [],
      pagination: {}
    };
  }
}

export async function createGroup({ name, active, permissions }) {
  try {
    const params = snakecaseKeys({
      name,
      active,
      permissions
    });
    const {
      status: resStatus,
    } = await http.post(groupsApi.create, params);
    if (resStatus === 200) {
      return {
        success: true
      };
    }
    return {
      success: false
    };
  } catch (error) {
    notification.error({
      duration: 5,
      placement: 'bottom',
      description: error.response.data?.message,
    });

    return {
      success: false
    };
  }
}

export async function updateGroup({ id, name, active, permissions }) {
  try {
    const url = generatePath(groupsApi.update, { id });
    const params = snakecaseKeys({
      name,
      active,
      permissions
    });
    const {
      status: resStatus,
    } = await http.put(url, params);
    if (resStatus === 200) {
      return {
        success: true
      };
    }
    return {
      success: false
    };
  } catch (error) {
    notification.error({
      duration: 5,
      placement: 'bottom',
      description: error.response.data?.message,
    });

    return {
      success: false
    };
  }
}

export async function getGroup({ id }) {
  try {
    const url = generatePath(groupsApi.detail, { id });
    const { status: resStatus, data } = await http.get(url);
    if (resStatus === 200) {
      return {
        success: true,
        data: data || [],
      };
    }
    return {
      success: false,
      data: null
    };
  } catch (error) {
    //
    notification.error({
      duration: 5,
      placement: 'bottom',
      description: error.response.data?.message,
    });

    return {
      success: false,
      data: null
    };
  }
}
