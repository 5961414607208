import React from 'react';
import Routes from '@/routes';
import { BrowserRouter as Router } from 'react-router-dom';
import { mobileAndTabletCheck } from '@/utils';
import MobileAccessWarning from '@/pages/MobileAccessWaring';
import './App.scss';


function App() {
  const isMobileOrTablet = mobileAndTabletCheck();

  return (
    isMobileOrTablet ?
      (
        <MobileAccessWarning />
      ) : (
        <Router>
          <Routes />
        </Router>
      )
  );
}

export default App;
