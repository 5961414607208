import React, { useEffect, useState } from 'react';
import {
  Typography,
} from 'antd';
import KycList from '@/components/Kyc/List';
import FilterComponent from '@/components/shared/Filter';
import { KycService } from '@/services';
import moment from 'moment';
import parsePhoneNumber from 'libphonenumber-js';
import { OPTS_STATUS_KYC } from '@/constants/kyc';

const { Title } = Typography;

function KycContainer() {
  const [data, setData] = useState();
  const [loading, setLoading] = useState(false);
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 10,
    showSizeChanger: true,
  });
  const [fitlerData, setFilterData] = useState({});

  const fetchData = async (params = {}) => {
    setLoading(true);
    KycService.getKycs(params).then(response => {
      if (response.success) {
        setPagination({
          ...pagination,
          total: response.pagination?.total,
          pageSize: response.pagination?.limit,
          current: response.pagination?.currentPage,
        });
        const list = response.data?.map(obj => ({
          ...obj,
          createdAt: moment(new Date(obj.createdAt)).format('DD/MM/YY, hh:mma'),
          updatedAt: moment(new Date(obj.updatedAt)).format('DD/MM/YY, hh:mma'),
          contactNumber: obj.contactNumber ? parsePhoneNumber(`+${obj.contactNumber}`).formatInternational() : ''
        }));
        setData(list || []);
      }
      setLoading(false);
    });
  };

  const handleApproveKyc = async (kycId) => {
    const res = await KycService.approveKyc({ id: kycId });

    if (res.status === 200) {
      fetchData({
        ...fitlerData,
        ...pagination,
      });
    }
  };

  const handleRejectKyc = async (payload) => {
    const res = await KycService.rejectKyc(payload);

    if (res.status === 200) {
      fetchData({
        ...fitlerData,
        ...pagination,
      });
    }

    return res;
  };

  useEffect(() => {
    fetchData(pagination);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleTableChange = (newPagination) => {
    fetchData({
      ...fitlerData,
      ...newPagination,
    });
  };

  const handleOnFilter = (values) => {
    fetchData({
      ...fitlerData,
      ...pagination,
      ...values
    });
    setFilterData({ ...fitlerData, ...pagination, ...values });
  };

  const fields = [
    {
      label: 'Status',
      name: 'status',
      typeInput: 'select',
      placeholder: 'Select status',
      options: OPTS_STATUS_KYC,
      col: '5'
    },
  ];
  return (
    <>
      <Title level={3}>KYC List</Title>
      <FilterComponent
        fields={fields}
        handleUpdate={handleOnFilter}
      />
      <KycList
        data={data}
        setData={setData}
        loading={loading}
        pagination={pagination}
        handleRejectKyc={handleRejectKyc}
        handleApproveKyc={handleApproveKyc}
        onTableChange={handleTableChange}
      />
    </>
  );
}

export default KycContainer;
