import React from 'react';
import { Result } from 'antd';
import moment from 'moment';

import logoWhite from '@/assets/images/logo-white.svg';

import './index.scss';

function MobileAccessWarning() {
  return (
    <div className="mobile-access-warming-container">
      <div className="header">
        <div className="header__logo">
          <img
            src={logoWhite}
            alt="Radical Pay"
            width="100%"
            height="100%"
          />
        </div>
      </div>
      <div className="content">
        <Result
          status="warning"
          title="This page can only be accessed on PC"
        />
      </div>
      <div className="footer">
        <span>© {moment().format('YYYY')} RadicalPay. All right reserved.</span>
      </div>
    </div>
  );
}

export default MobileAccessWarning;
