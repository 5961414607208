import React, { useState } from 'react';
import {
  Button,
  Card,
  Spin,
} from 'antd';
import { useHistory, useParams } from 'react-router-dom';

import GroupForm from '@/components/Group/Form';
import { GroupService } from '@/services';
import { ROUTES } from '@/constants';
import { useBeforeRender } from '@/utils';


function EditContainer() {
  const { id } = useParams();
  const history = useHistory();
  const [loading, setLoading] = useState(true);
  const [formData, setFormData] = useState({ active: true });
  const handleOnFinish = async values => {
    const { success } = await GroupService.updateGroup({ id, ...values });
    if (success) {
      history.push(ROUTES.GROUPS.INDEX);
    }
  };

  const fetchData = async () => {
    const { success, data } = await GroupService.getGroup({ id });
    if (success) {
      setFormData({
        ...data,
        permissions: data.permissions || []
      });
      setLoading(false);
    }
  };

  const onBack = () => {
    history.push(ROUTES.GROUPS.INDEX);
  };

  useBeforeRender(() => fetchData(), []);
  return (
    <Spin spinning={loading}>
      <Button shape="round" onClick={onBack}>
        Back
      </Button>
      <Card title="Edit Group" className='mt-10'>
        <GroupForm
          handleOnFinish={handleOnFinish}
          formData={formData}
        />
      </Card >
    </Spin>
  );
}

export default EditContainer;
