import React from 'react';

import EditContainer from '@/containers/Groups/edit';
import { useCheckAccessBefore } from '@/utils';
import { PERMISSION } from '@/constants/permission';

function EditGroupPage() {
  useCheckAccessBefore(PERMISSION.GROUP_WRITE);

  return (
    <EditContainer />
  );
}

export default EditGroupPage;
