import React from 'react';
import { Switch } from 'react-router-dom';
import AuthRoute from '@/routes/AuthRoute';
import GuestRoute from '@/routes/GuestRoute';
import KycPage from '@/pages/KycPage';
import KycDetail from '@/pages/KycPage/detail';
import DashboadPage from '@/pages/DashboardPage';
import NotFoundPage from '@/pages/NotFoundPage';
import LoginPage from '@/pages/LoginPage';
import { ROUTES } from '@/constants';
import TransactionsPage from '@/pages/Transaction';
import TransactionDetailPage from '@/pages/Transaction/detail';
import UsersPage from '@/pages/Users';
import UserEditPage from '@/pages/Users/edit';
import GroupsPage from '@/pages/GroupPage';
import GroupNewPage from '@/pages/GroupPage/new';
import GroupEditPage from '@/pages/GroupPage/edit';

function Routes() {
  return (
    <Switch>
      <GuestRoute
        exact
        path={ROUTES.ROOT_PATH}
        component={LoginPage}
      />
      <GuestRoute
        path={ROUTES.LOGIN_PATH}
        component={LoginPage}
      />
      <AuthRoute
        exact
        path='/'
        component={DashboadPage}
      />
      <AuthRoute
        exact
        path='/dashboard'
        component={DashboadPage}
      />
      <AuthRoute
        exact
        path={ROUTES.KYC.INDEX}
        component={KycPage}
      />
      <AuthRoute
        exact
        path={ROUTES.KYC.DETAIL}
        component={KycDetail}
      />
      <AuthRoute
        exact
        path={ROUTES.TRANSACTION.INDEX}
        component={TransactionsPage}
      />
      <AuthRoute
        exact
        path={ROUTES.TRANSACTION.DETAIL}
        component={TransactionDetailPage}
      />
      <AuthRoute
        exact
        path={ROUTES.USERS.EDIT}
        component={UserEditPage}
      />
      <AuthRoute
        exact
        path={ROUTES.USERS.INDEX}
        component={UsersPage}
      />
      <AuthRoute
        exact
        path={ROUTES.GROUPS.NEW}
        component={GroupNewPage}
      />
      <AuthRoute
        exact
        path={ROUTES.GROUPS.EDIT}
        component={GroupEditPage}
      />
      <AuthRoute
        exact
        path={ROUTES.GROUPS.INDEX}
        component={GroupsPage}
      />
      <GuestRoute
        path='*'
        component={NotFoundPage}
      />
    </Switch>
  );
}

export default Routes;
