import React from 'react';
import Proptypes from 'prop-types';
import {
  Form,
  Row,
  Col,
  Button,
  Input,
  Select,
  DatePicker
} from 'antd';
import {
  uniqueId
} from 'lodash';

import './index.scss';

const { Option } = Select;
const { RangePicker } = DatePicker;

function Filter({
  handleUpdate,
  fields,
  dataForm,
  exportCSV,
  hasExport
}) {
  const handleOnFinish = (values) => {
    handleUpdate(values);
  };

  const renderField = (field) => {
    switch (field.typeInput) {
      case 'string':
        return renderStringField(field);
      case 'select':
        return renderSelectField(field);
      case 'date-range':
        return renderDateRange(field);
      default:
        return renderStringField(field);
    }
  };

  const renderStringField = ({
    label,
    name,
    placeholder,
    col,
    typeInput
  }) => (
    <Col key={uniqueId(name)} span={col || '12'}>
      {label && (<label htmlFor='#'>{label}</label>)}
      <Form.Item
        label=""
        name={name}
      >
        <Input
          size="large"
          name={name}
          type={typeInput}
          placeholder={placeholder}
        />
      </Form.Item>
    </Col>
  );

  const renderSelectField = ({
    label,
    name,
    placeholder,
    col,
    options
  }) => (
    <Col key={uniqueId(name)} span={col || '12'}>
      {label && (<label htmlFor='#'>{label}</label>)}
      <Form.Item
        label=''
        name={name}
      >
        <Select
          name={name}
          placeholder={placeholder}
          size="large"
          allowClear
        >
          {options.map(
            ({ label: labelOpt, value }) => <Option key={uniqueId(value)} value={value}>{labelOpt}</Option>
          )}
        </Select>
      </Form.Item>
    </Col>
  );

  const renderDateRange = ({
    label,
    name,
    col,
  }) => (
    <Col key={uniqueId(name)} span={col || '12'}>
      {label && (<label htmlFor='#'>{label}</label>)}
      <Form.Item
        label=''
        name={name}
      >
        <RangePicker
          size="large"
          name={name}
          style={{ 'width': '100%' }}
          allowClear
        />
      </Form.Item>
    </Col>
  );

  return (
    <Form
      name='basic'
      autoComplete='off'
      layout='horizontal'
      onFinish={handleOnFinish}
      initialValues={dataForm}
    >
      <Row gutter={[12, 0]}>
        {
          fields.map(renderField)
        }
        <Col span='6' className='col-filter --group'>
          <Button
            size='large'
            type="primary"
            htmlType="submit"
          >
            Search
          </Button>
          {
            hasExport && <Button type='primary' onClick={exportCSV} size='large'>
              Export CSV
            </Button>
          }
        </Col>
      </Row>
    </Form>
  );
};

Filter.propTypes = {
  handleUpdate: Proptypes.func.isRequired,
  fields: Proptypes.arrayOf(Proptypes.object).isRequired,
  dataForm: Proptypes.object,
  exportCSV: Proptypes.func,
  hasExport: Proptypes.bool
};

export default Filter;
