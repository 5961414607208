import snakecaseKeys from 'snakecase-keys';
import camelcaseKeys from 'camelcase-keys';
import {
  generatePath,
} from 'react-router-dom';
import { notification } from 'antd';

import http from '@/http';
import { kycApi } from '@/constants/api';
import { KycReviewStatuses } from '@/constants/kyc';

export async function getKycs({ status, current, pageSize = 10 }) {
  try {
    const params = snakecaseKeys({
      status,
      limit: pageSize,
      page: current,
    });
    const res = await http.get(kycApi.getListKycs, { params });
    if (res.status === 200) {
      const result = camelcaseKeys(res.data, { deep: true });
      return {
        success: true,
        data: result.data,
        pagination: result.pagination || {},
      };
    }

    return {
      success: false,
      data: [],
      pagination: {},
    };
  } catch (error) {
    notification.error({
      duration: 5,
      placement: 'bottom',
      description: error.response.data?.message,
    });

    return {
      success: false,
      data: [],
      pagination: {},
    };
  }
}

export async function approveKyc({ id }) {
  const url = generatePath(kycApi.reviewKyc, { id });

  try {
    const res = await http.post(url, { status: KycReviewStatuses.approve });

    notification.success({
      duration: 5,
      placement: 'bottom',
      description: 'Successfully Approved ',
    });

    return res;
  } catch (error) {
    notification.error({
      duration: 5,
      placement: 'bottom',
      description: error.response.data?.message || 'Something went wrong!',
    });

    return error.response;
  }
}

export async function rejectKyc({ id, reason, rejectedCode }) {
  const url = generatePath(kycApi.reviewKyc, { id });
  const payload = snakecaseKeys({
    reason,
    rejectedCode,
    status: KycReviewStatuses.reject,
  });

  try {
    const res = await http.post(url, payload);
    return res;
  } catch (error) {
    console.log('error', error);
    notification.error({
      duration: 5,
      placement: 'bottom',
      description: error?.response?.data?.message || 'Something went wrong!',
    });

    return error.response;
  }
}

export async function getKycDetail({ id }) {
  try {
    const url = generatePath(kycApi.detail, { id });
    const res = await http.get(url);
    return camelcaseKeys(res.data, { deep: true });
  } catch (error) {
    notification.error({
      duration: 5,
      placement: 'bottom',
      description: error?.response?.data?.message || 'Something went wrong!',
    });
    return error.response;
  }
}

export async function getFile({ id }) {
  try {
    const url = generatePath(kycApi.getFile, { id });
    const res = await http.get(url);
    return camelcaseKeys(res.data, { deep: true });
  } catch (error) {
    notification.error({
      duration: 5,
      placement: 'bottom',
      description: error?.response?.data?.message || 'Something went wrong!',
    });
    return error.response;
  }
}
