import snakecaseKeys from 'snakecase-keys';
import { notification, message } from 'antd';
import camelcaseKeys from 'camelcase-keys';
import { generatePath } from 'react-router-dom';
import { pickBy, identity } from 'lodash';

import http from '@/http';
import { transactionAPI } from '@/constants/api';

export async function getTransactions({
  createdFrom,
  createdTo,
  status,
  id,
  refType,
  refId,
  current,
  merchantId,
  orderId,
  pageSize = 10
}) {
  try {
    const params = snakecaseKeys({
      limit: pageSize,
      page: current,
      createdFrom,
      createdTo,
      status,
      id,
      refType,
      refId,
      merchantId,
      orderId
    });
    const { status: resStatus, data } = await http.get(transactionAPI.index, { params });
    if (resStatus === 200) {
      return {
        success: true,
        ...camelcaseKeys(data, { deep: true }),
      };
    }
    return {
      success: false,
      data: [],
      pagination: {}
    };
  } catch (error) {
    notification.error({
      duration: 5,
      placement: 'bottom',
      description: error?.response?.data?.message || 'Have something wrong',
    });
    return {
      success: false,
      data: [],
      pagination: {},
    };
  }
}

export async function getTransactionsDetail({ id }) {
  try {
    const url = generatePath(transactionAPI.detail, { id });
    const { status: resStatus, data } = await http.get(url);
    if (resStatus === 200) {
      return {
        success: true,
        data: camelcaseKeys(data, { deep: true })
      };
    }
    return {
      success: false,
      data: {}
    };
  } catch (error) {
    notification.error({
      duration: 5,
      placement: 'bottom',
      description: error?.response?.data?.message || 'Have something wrong',
    });
    return {
      success: false,
      data: {}
    };
  }
}

export async function exportTransactions({
  createdFrom,
  createdTo,
  status,
  id,
  refType,
  refId,
  orderId,
  merchantId
}) {
  try {
    const params = snakecaseKeys({
      createdFrom,
      createdTo,
      status,
      id,
      refType,
      refId,
      orderId,
      merchantId,
      timezone: Intl.DateTimeFormat().resolvedOptions().timeZone
    });
    const result = pickBy(params, identity);
    const searchParams = new URLSearchParams(result);
    let url = transactionAPI.exportCSV;

    url = `${url}?${searchParams}`;

    const { data } = await http.post(url);

    downloadCSV({ csvStr: data, nameFile: 'transaction' });
    return camelcaseKeys(data, { deep: true });
  } catch (error) {
    notification.error({
      duration: 5,
      placement: 'bottom',
      description: error?.response?.data?.message || 'Have something wrong',
    });
    return error.response;
  }
}

export function downloadCSV({ csvStr, nameFile }) {
  const hiddenElement = document.createElement('a');
  hiddenElement.href = `data:text/csv;charset=utf-8,${encodeURI(csvStr)}`;
  hiddenElement.target = '_blank';
  hiddenElement.download = `${nameFile}.csv`;
  hiddenElement.click();
}


export async function updateStatusTransaction({ id, status }) {
  try {
    const params = snakecaseKeys({
      status
    });
    const url = generatePath(transactionAPI.updateStatus, { id });
    const { status: resStatus, data } = await http.post(url, params);
    if (resStatus === 200) {
      return {
        success: true,
        data: camelcaseKeys(data, { deep: true })
      };
    }
    return {
      success: false,
      data: {}
    };
  } catch (error) {
    notification.error({
      duration: 5,
      placement: 'bottom',
      description: error?.response?.data?.message || 'Have something wrong',
    });
    return {
      success: false,
      data: {}
    };
  }
}


export async function uploadMassUpdateTransaction({ file }) {
  try {
    const formData = new FormData();
    formData.append('file', file);

    await http.post(transactionAPI.massUpdate, formData, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    });
    message.success('Mass Update Transactions Successfully');
    return {
      success: true,
      status: 200
    };
  } catch (error) {
    return {
      success: false,
      status: error?.response?.status,
      data: error?.response?.data
    };
  }
}

export async function getMassTemplate() {
  try {
    const res = await http.get(transactionAPI.massTemplate);

    if (res.status === 200) {
      downloadCSV({ csvStr: res.data, nameFile: 'file_example' });
    }
  } catch (error) {
    notification.error({
      duration: 5,
      placement: 'bottom',
      description: error?.response?.data?.message || 'Something wrong',
    });
  }
}
