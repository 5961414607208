import http from '@/http';
import snakecaseKeys from 'snakecase-keys';
import camelcaseKeys from 'camelcase-keys';
import { notification } from 'antd';

import { ROUTES } from '@/constants';
import { authApi } from '@/constants/api';
import { setAuthUser } from '@/utils/localStorageUtil';

export async function oauthLogin({ ggAccessToken }) {
  try {
    const payload = snakecaseKeys({
      accessToken: ggAccessToken,
      provider: 'google'
    });
    const res = await http.post(authApi.oauthLogin, payload);
    if (res.status === 200) {
      const data = camelcaseKeys(res.data, { deep: true });
      setAuthUser(data);
      notification.success({
        duration: 5,
        placement: 'bottom',
        description: 'Login Successfully',
      });

      window.location.href = ROUTES.DASHBOARD_PATH;

    }
    return camelcaseKeys(res, { deep: true });
  } catch (error) {
    notification.error({
      duration: 5,
      placement: 'bottom',
      description: error.response.data?.message || 'Something went wrong!',
    });

    return error.response;
  }
}
